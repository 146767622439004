.carousel {
    position: relative;
    overflow: hidden;
}

.carousel-wrapper {
    display: flex;
    transition: transform 0.5s ease;
}

.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}