@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: #4c4c4c;
}

:root {
  --brandColor1: #029ac9;
  --brandColor2: #006838;
  --brandColor3:#f7941d;
  --brandColor4: #ea0054;
  --black-color: #000000;
  ;
  --white-color: #ffff;
  --grey-color: rgb(214, 214, 214);

  /* //light */
  --brandColorl3:#ffedd7c9;

}

h2 {
  font-size: 1.5rem;
}

.h1, h1 {
  font-size: calc(1.375rem + 1.8vw);
}

/* p {
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 1rem;
} */

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; 
}

.logo {
  width: 100px;
}

.navbar {
  position: relative;
  padding: 0rem 1rem;
}

.navbar .container {
  border-bottom: 1.5px solid;
}

.container {
  --bs-gutter-x: 0rem !important;

}

.openNav {
  background: none;
}

.menu-icon {
  display: none;
}

/* .nav-elements {} */

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

/* .glass {
  background-image: url('../assets/emmanuel.jpg');
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);

} */


.background-image {
  background-image: url('../assets/emmanuel.jpg');

  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  overflow: hidden;
  backdrop-filter: blur(90px);
  -webkit-backdrop-filter: blur(90px);
  background-color: rgba(255, 255, 255, 0.5);
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgb(255, 250, 245)) */
}

.content-overlay {
  padding: 20px;
  background-color: rgba(233, 233, 233, 0.8);
  z-index: 1;
  /* Ensure content is above blurred background */
}

.col-rps {
  /* display: flex; */
  width:  50%;
}


/* Universal */

/* Authentication Page Login & Logout */

.auth {
  /* width: 500px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login, .register {
  text-align: center;
  justify-content: center;
  align-items: center;
  /* width: 100% !important; */
  padding: 100px 30% 100px 30%;
}


.form-group {
  margin-bottom: 20px;
}



input, textarea {
  width: 100%;
  padding: 10px !important;
  font-size: 16px;
  border: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 5px;
  margin: 10px 0;
  box-sizing: border-box;
  /* Ensure padding and border are included in width */
}

/*  Buttons */

button {
  /* padding: 10px 20px; */
  font-size: 16px;
  border: none;
  border-radius: 50px;
  /* border-radius: 50%; */
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.primary-button {
  color: var(--white-color);
  background-color: var(--black-color);
}

.secondary-button {
  color: var(--black-color);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button:hover,
.secondary-button:hover {
  background-color: #f0f0f0;
  color: var(--white-color) !important
}

.button-group {
  display: flex;
  justify-content: start;
  gap: 1rem;
}

.btn-one {
  background: var(--black-color) !important;
  border: solid 1px;

}

a {
  color: inherit !important;
  padding: 0px;
  text-decoration: none !important;
}

.a{
  color: var(--brandColor4) !important;
}

.li {
  list-style: disc;

}

.link a {
  color: var(--white-color) !important;
  /* background-color: none; */
  list-style: none;
  background-color: var(--black-color) !important;
}

.line {
  /* width: 50%; */
  border-bottom: solid 1px;
  margin-bottom: 8px;
}



/* form {
  width: 100%;
  display: flex;
  flex-direction: column;
} */

.extra {
  display: flex;
  justify-content: space-between !important;
  /* text-align: start; */
  display: flex;
  /* flex-direction: column; */
}

.mobile {
  display: none;
}




.cx {
  display: flex;
  justify-content: center;
}

.row-x {
  display: flex;
  flex-wrap: wrap;
  /* width: 100%; */
  overflow-x: auto;
  margin: 0 -10px;
  /* Adjust according to your needs */
}

/*  card for carousels */
/* .card-x {
  flex: 0 0 calc(33.333% - 20px);
  margin: 10px;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
} */

.card-x {
  flex: 0 0 calc(33.333% - 20px);
  margin: 10px;
  background-color: var(--white-color);
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.card-x:hover {
  background-color:var(--brandColorl3)!important;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}


/* other cards */
.card-o {
  flex: 0 0 calc(33.333% - 20px);
  margin: 10px;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
}

.form-type-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--brandColor3); /* Adjust to your preferred color */
  color: white;
  padding: 5px 10px;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 8px; /* Rounded badge */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  z-index: 1;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Adds space between the checkbox and text */
}
 .checkbox {
  width: 10% !important;
  padding: 10px !important;
}
.footer-flex {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}


/* social Media */

.social-icons {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.social-icon {
  margin: 0 10px;
  color: #333; /* Adjust icon color */
  text-decoration: none; /* Remove default underline */
  transition: color 0.3s ease; /* Smooth color transition */
}

.social-icon:hover {
  color: #3b5998; /* Adjust hover color */
}




/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  width: 100%;

  /* text-align: center; */
  padding: 20px 0;
  flex-shrink: 0;
}

/* Pages */

.about p {
  text-align: justify;
}

.form-receipt {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #fff;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.form-receipt .receipt-details p {
  font-size: 14px;
  margin: 5px 0;
}

.form-receipt .footer {
  text-align: center;
  font-size: 12px;
  color: #555;
}


/* @media screen and (max-width: 820px) {
  
} */

@media screen and (max-width: 768px) {
  .navbar .container {
    border-bottom: none;
  }

  .desktop {
    display: none;
  }
  /* .card-x {
    flex: 0 0 calc(33.333% - 20px);
  flex-basis: calc(100%);
  } */

  

  .mobile {
    display: block;
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .container {
    padding: 10px;
  }

  .navbar-collapse {
    /* margin-top: 25px; */
    flex-basis: 100%;
    flex-grow: 1;
    /* background: white; */
    align-items: center;

  }


  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  @keyframes backgroundGradient {
    0% {
      background-color: #dc3545; /* Start color */
    }
    100% {
      background-color: #198754; /* End color */
    }
  }

  .hero-section {
    position: relative;
    height: 100vh;
    background: #0000;
    /* background-image: url('.././assets/emmanuel.jpg'); */
    animation: backgroundGradient 3s infinite alternate;
    /* Direct URL to the image */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @keyframes gradientShift {
    0% {
      background: linear-gradient(90deg, #dc3545, #198754);
    }
    50% {
      background: linear-gradient(90deg, #198754, #dc3545);
    }
    100% {
      background: linear-gradient(90deg, #dc3545, #198754);
    }
  }
  
  .gradient-background {
    width: 100%;
    height: 100vh;
    animation: gradientShift 5s infinite alternate;
    background-size: 200% 200%;
  }
  

  .hero-content {
    color: white;
  }

  .hero-text {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .subheading {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }


.col-rps {
  width: 100%;
  margin-bottom: 10px;
}
.auth {
  width: 100% !important;
  display: flex;
  /* flex-direction: column; */
}

.login, .register {
  align-items: center;
  width: 100%;
  padding: 10px;
}

/* other cards */
.card-o {
  flex: 0 0 calc(100% - 20px);
  /* margin: 10px;
  background-color: #f0f0f0;
  padding: 20px; */
  box-sizing: border-box;
}
.footer-flex {
  display: flex !important;
  flex-direction: column;
}


}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    /* background-color: #fef7e5; */
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .img {
    width: 100%;
    height: auto;
  }



  /* button {
    margin-right: 10px;
  } */
.col-4,
  .footer-col{
    width: 100%;
  }

}


@media screen and (max-width: 768px) {
  .hero-content {
      color: white;
      padding: 15%
  }
}



/* @media screen and (max-width: 767px) {
  .card-x {
    flex: 0 0 calc(50% - 20px);
  }
} */

@media screen and (max-width: 479px) {
  .card-x {
    flex: 0 0 calc(100% - 20px);
    /* Adjust according to your needs */
  }
}

@media screen and (max-width: 820px) {
    .footer-flex {
        display: flex !important;
        flex-direction: column;
    }

    .card-x, .card-o {
      flex-basis: calc(100%) !important; /* Keeps the element at full width */
      transition: all 0.3s ease; /* Smooth transition for hover effects */
    }
  
    .row-x {
      /* display: flex; */
      /* flex-wrap: nowrap !important; */
      width: 100% !important;
  
    }
}







/* designs */

.circle {
  clip-path: circle(50% at center);
}

/* .custom-shape {
  clip-path: path('M50,0 L100,100 L0,100 ');
} */

.ellipse {
  clip-path: ellipse(50% 50% at left);
}


.clipped {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* background-color: #000000; */
}

.clipped-element {
  width: 20px;
  height: 80px;
  border-radius: 100px;
  background-color: var(--brandColor4);
  clip-path: url(#myClip);
}

.clipped-element1 {
  width: 50px;
  height: 40px;
  border-top-right-radius: 50%;
  background-color: var(--brandColor3);
  /* opacity: 80%; */
  clip-path: url(#myClip);
}

.clipped-element2 {
  width: 50px;
  height: 50px;
  border-top-right-radius: 50%;
  background-color: var(--brandColor1);
  /* background: black; */

  clip-path: url(#myClip);
}


.centered {
  display: grid;
  place-items: center;
  /* height: 100vh; */

}

/* CSS to hide everything except the receipt during print */
@media print {
  body * {
    visibility: hidden;
  }

  .printable-receipt, .printable-receipt * {
    visibility: visible;
  }

  .printable-receipt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
