.banner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the banner is on top */
  }
  
  .banner {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
    position: relative;
    max-width: 500px;
    width: 90%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #d9534f;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .close-button:hover {
    background-color: #c9302c;
  }
  