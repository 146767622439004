/* FormModal.css */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* max-height: 100vh;  */
}

.custom-modal-container {
    background-color: #fff;
    /* border-radius: 8px; */
    width: 100%;
    max-width: 800px;
    height: 100vh; /* Limit the height of the modal */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.nav-tabs .nav-item {
    margin-bottom: 20px;
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.custom-modal-header h2 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.custom-modal-body {
    margin-top: 20px;
    width: 100%;
    flex-grow: 1; /* Allow the body to expand and push the footer down */
}

.custom-modal-footer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.close-button-footer {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.close-button-footer:hover {
    background-color: #0056b3;
}

.alert {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
}

@media screen and (min-width: 900px) {
    .custom-modal-container {
        max-width: 800px;
        max-height: 90vh; /* Ensure the modal height is constrained */
        margin: 20px;
        padding: 20px;
    }
}
